import React from 'react';
import '../../Styles/footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import Logo from '../../Assets/HomeImages/Astro-logo.svg';
import Logo2 from '../../Assets/HomeImages/Astro-logo-2.png';
import Email from '../../Assets/HomeImages/email-icon.svg';
import Phone from '../../Assets/HomeImages/phone-icon.svg';
// import BackBtn from '../../Assets/HomeImages/back-btn-img.svg';

import { FaArrowRightLong } from "react-icons/fa6";


import Facebook from '../../Assets/SocialmediaImages/fb.png';
import Instagram from '../../Assets/SocialmediaImages/insta.png';
import LinkedIn from '../../Assets/SocialmediaImages/linkedin.png';
import Twitter from '../../Assets/SocialmediaImages/twitter.svg';
import Youtube from '../../Assets/SocialmediaImages/youtube-icon.svg';
import Pinterest from '../../Assets/SocialmediaImages/pinterest.svg';

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const Footer = () => {
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    return (
        <>
            {/* {window.location.pathname === "/" ? null :
                <div className='back-btn'>
                    <button className='bg-orange' onClick={() => navigate(-1)}>
                        <img src={BackBtn} alt="" />
                    </button>
                </div>
            } */}

            {/* {
                (pathname === '/chat/' || pathname === '/customer-support/') ?
                    null
                    :
                    <WhatsAppWidget
                        phoneNo="918111023733"
                        position="left"
                        widgetWidth="300px"
                        widgetWidthMobile="260px"
                        // autoOpen={true}
                        // autoOpenTimer={5000}
                        iconSize="50"
                        iconColor="white"
                        iconBgColor="#16BE45"
                        headerIcon="https://www.astromindcounsel.com/static/media/Astro-logo.5f95a20c254126bb951c0f857b54708a.svg"
                        headerIconColor="pink"
                        headerTxtColor="#ffffff"
                        headerBgColor="#0a5f54"
                        headerTitle="Astromind Counsel"
                        headerCaption="Official WhatsApp Account"
                        bodyBgColor="#bbb"
                        chatPersonName="Support"
                        chatMessage={<>Hi there 👋 <br /> How can I help you?</>}
                        footerBgColor="#bbb"
                        placeholder="Type a message.."
                        btnBgColor="#16BE45"
                        btnTxt="Start Chat"
                        btnTxtColor="#ffffff"
                    />
            } */}


            <Container fluid className={pathname === '/chat/' || pathname === '/customer-support/' ? "hide-footer" : "bg-blue pt-3"}>
                <Container >
                    <Row className="d-flex justify-content-between footer-about">
                        <Col lg={4} sm={6} className="about">
                            <div className="footer-logo">
                                <img src={Logo} alt="" />
                            </div>
                            <div className="pt-3">
                                <p className="text-white">The combination of astrology and psychology involves using astrological principles and symbolism as a framework for understanding and exploring psychological patterns and processes.</p>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} className="pt-3 footer-service">
                            <div>
                                <h5 className="text-white">QUICK LINKS</h5>
                            </div>
                            <div className="pt-2 text-white service-links">
                                <div>
                                    <a href='/about/'>
                                        <p>About Us</p>
                                    </a>
                                    <a href='/get-your-astrology-reports/'>
                                        <p>Contact Us</p>
                                    </a>

                                </div>
                                <div>
                                    <a href='/blogs/'>
                                        <p>Blogs</p>
                                    </a>
                                    <a href='/faqs/'>
                                        <p>FAQs</p>
                                    </a>
                                </div>
                                <div>
                                    <a href='/contact-us/'>
                                        <p>Personalized Prediction</p>
                                    </a>
                                </div>
                            </div>

                            <div className="pt-2">
                                <h5 className="text-white">SERVICES</h5>
                            </div>
                            <div className="pt-2 text-white service-links">
                                <div>
                                    <a href='/services/online-vedic-astrology/'>
                                        <p>Vedic Astrology</p>
                                    </a>

                                    <a href='/services/couples-horoscope-astrology/'>
                                        <p>Couples</p>
                                    </a>
                                    <a href='/services/teen-horoscope-astrology-services/'>
                                        <p>Teens</p>
                                    </a>
                                </div>
                                <div>
                                    <a href='/services/get-personalized-nadi-astrology/'>
                                        <p>Nadi Astrology</p>
                                    </a>
                                    <a href='/services/tamil-jathagam-for-Midlife-crisis/'>
                                        <p>Mid Life Crisis</p>
                                    </a>
                                    <a href='/services/tamil-jathagam-services-for-elder-age/'>
                                        <p>Elderly</p>
                                    </a>
                                </div>
                                <div>
                                    <a href='/services/tamil-jathagam-service-for-your-children/'>
                                        <p>Children</p>
                                    </a>
                                    <a href='/services/end-of-life-horoscope-astrology-services/'>
                                        <p>End Of Life</p>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} className="pt-3">

                            <div className="text-white about">
                                <div>
                                    <h5 className="text-white">JOB OPPORTUNITY </h5>
                                </div>
                                <div className="">
                                    <p className="text-white mb-2">Become part of Astromind Counsel’s dynamic team shaping the future of online astrology.
                                    </p>
                                </div>
                                <a href='/astrologer-register/'>
                                    <Button className="bg-white text-blue footer-apply-btn">
                                        Apply Now <FaArrowRightLong />
                                    </Button>
                                </a>
                            </div>
                            <div className="contact-head pt-3">
                                <h5 className="text-white">CONTACT US</h5>
                            </div>
                            <div className="contact-main">
                                <div className="pt-2 contact-details">
                                    <div className="d-flex align-items-center gap-3 text-white footer-contact">
                                        <img src={Email} alt="" />
                                        <p>astromindcounsel@gmail.com</p>
                                    </div>
                                    <div className="d-flex gap-3 text-white footer-contact pt-2">
                                        <img src={Phone} alt="" />
                                        <p className='number'>+91 94459 50005, 8111023733</p>
                                    </div>
                                </div>
                                {/* <div className="pt-3 footer-contact newsletter">
                                    <p className="text-white">Subscribe To Our Newsletter</p>
                                    <form action="" className="d-flex pt-2 newsletter-form">
                                        <input type="email" name="email" id="email" placeholder="Enter your Email Address" />
                                        <div className="bg-white newsletter-submit">
                                            <img src={Send} alt="" />
                                        </div>
                                    </form>

                                </div> */}

                                <div className='d-flex mt-3'>
                                    <div className='socialMedia-icon'>
                                        <a href="https://www.facebook.com/profile.php?id=61555195043116" target='_blank' rel="noreferrer">
                                            <img src={Facebook} alt="" />
                                        </a>
                                    </div>
                                    <div className='socialMedia-icon'>
                                        <a href="https://www.linkedin.com/company/astromind-counsel" target='_blank' rel="noreferrer">
                                            <img src={LinkedIn} alt="" />
                                        </a>
                                    </div >
                                    <div className='socialMedia-icon'>
                                        <a href="https://www.instagram.com/astromindcounsel/" target='_blank' rel="noreferrer">
                                            <img src={Instagram} alt="" />
                                        </a>
                                    </div>
                                    <div className='socialMedia-icon'>
                                        <a href="https://twitter.com/astromind123" target='_blank' rel="noreferrer">
                                            <img src={Twitter} alt="" />
                                        </a>
                                    </div>
                                    <div className='youtube-icon'>
                                        <a href="https://www.pinterest.com/astromindcounsel/" target='_blank' rel="noreferrer">
                                            <img src={Pinterest} alt="" />
                                        </a>
                                    </div>
                                    <div className='youtube-icon'>
                                        <a href="https://www.youtube.com/channel/UCoVOb4A0Jhk0zLlSNv1xI8g" target='_blank' rel="noreferrer">
                                            <img src={Youtube} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <hr className="text-white" />
                    <Row>
                        <Col lg={12} className="d-flex justify-content-between text-lightWhite footer-privacy">
                            <p>Copyrights@2024. All rights reserved</p>
                            {/* <p>Developed by <span>&#10084;</span> <a href='https://www.akkenna.com/' target='_blank' rel="noreferrer">Akkenna</a></p> */}
                            <p><a href='/privacy-policies/' target='_blank' rel="noreferrer">Privacy Policy </a> | <a href='/terms-of-use/' target='_blank' rel="noreferrer">Terms of use</a> | <a href='/refund-cancellation-policies/' target='_blank' rel="noreferrer">Refund Policies</a></p>
                        </Col>
                    </Row>
                </Container>

            </Container>

        </>
    )
}

export default Footer