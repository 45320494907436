import './App.css';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';

import Header from './Pages/CommonPages/Header';
import Footer from './Pages/CommonPages/Footer';
import Home from './Pages/HomePage/Home';
import About from './Pages/AboutPage/About';

// import Blog from './Pages/BlogPage/blog';
import Blog from './Pages/NewBlogPage/blog';

import BlogDetail from './Pages/BlogdetailPage/Jathagam/BlogDetail';
// import RajaNadi from './Pages/BlogdetailPage/Rajanadi/Rajanadi';
// import RajanadiConjunctions from './Pages/BlogdetailPage/RajanadiConjunctions/RajanadiConjunctions';
// import RajanadiConjunctionsPart2 from './Pages/BlogdetailPage/RajanadiConjunctions - part2/RajanadiConjunctionsPart2';
// import NewyearPredictions from './Pages/BlogdetailPage/NewYearPredictions/NewyearPredictions';
// import NewyearPredictionsPart2 from './Pages/BlogdetailPage/NewYearPredictions-part2/NewyearPredictions-part2';
// import AntonChekov from './Pages/BlogdetailPage/AntonChekov/AntonChekov';
// import RajanadiConjunctionsTamil from './Pages/BlogdetailPage/RajanadiConjunctionsTamil-part1/RajanadiConjunctionsTamil';

import Children from './Pages/ServicePage/Children/ChildrenIndex';
import Couples from './Pages/ServicePage/Couples/CouplesIndex';
import Elderly from './Pages/ServicePage/Elderly/ElderlyIndex';
import Endoflife from './Pages/ServicePage/Endoflife/EndoflifeIndex';
import Midlifecrisis from './Pages/ServicePage/Midlifecrisis/MidlifecrisisIndex';
import Teens from './Pages/ServicePage/Teens/TeensIndex';
import UnlockYourFuture from './Pages/ServicePage/UnlockYourFuture/UnlockYourFuture';
import EmpowerYourLife from './Pages/ServicePage/EmpowerYourLife/EmpowerYourLife';
import BestAstrologersInChennai from './Pages/ServicePage/BestAstrologersInChennai/BestAstrologersInChennai';
import TopAstrologersInCoimbatore from './Pages/ServicePage/TopAstrologersInCoimbatore/TopAstrologersInCoimbatore';
import PersonalizedHoroscope from './Pages/ServicePage/PersonalizedHoroscope/PersonalizedHoroscope';
import BestAstrologersInTrichy from './Pages/ServicePage/BestAstrologerInTrichy/BestAstrologerInTrichy';
import BestAstrologerInMadurai from './Pages/ServicePage/BestAstrologerInMadurai/BestAstrologerInMadurai'
import BestAstrologerInThanjavur from './Pages/ServicePage/BestAstrologerInThanjavur/BestAstrologerInThanjavur'

import Contact from './Pages/ContactPage/Contact';
import Astrologer from './Pages/Astrologer/AstrologerListPage/Astrologer';
import Profile from './Pages/Astrologer/AstrologerProfile/ProfileIndex';
import Faqs from './Pages/FaqsPage/Faqs';
import InPerson from './Pages/CoursePage/InpersonPage/InPerson';

import PrivacyPolicies from './Pages/CommonPages/privacyPolicies';
import RefundPolicies from './Pages/CommonPages/refundPolicies';
import TermsOfUse from './Pages/CommonPages/TermsOfUse';

import AstrologyReports from './Pages/AstrologyReports/AstrologyReports';

import Login from './Pages/User/Login/Login';
import UserProfile from './Pages/User/Profile/Profile';
import Notification from './Pages/User/Notification/Notification';
import MyWallet from './Pages/User/Payment/Wallet';
import RechargeList from './Pages/User/Payment/RechargeList';
import OrderHistoryList from './Pages/User/OrderHistory/OrderHistoryList';
import SupportChat from './Pages/User/SupportChat/SupportChat';

import Error404 from './Pages/CommonPages/Error404'

import AstrologerResgister from './Pages/Astrologer/Register/AstrologerRegister';
import ConsultationDetails from './Pages/Astrologer/Consultation/ConsultationDetails';
import ChatConnect from './Pages/Astrologer/ChatPage/ChatConnect';

import CallConnect from './Pages/Astrologer/AstrologerListPage/CallConnect';
import ChatWaiting from './Pages/Astrologer/AstrologerListPage/ChatWaiting';

import ChromeNotification from './Pages/CommonPages/ChromeNotification';
import BrowserNotification from './Pages/CommonPages/BrowserNotification';

import Swal from 'sweetalert2';

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { GetIPAddress, CheckLoginStatus, GetUserProfile, successMessage, errorMessage, LoginScreen } from '../src/Redux/Slice';
import { useEffect } from 'react';

import { HelmetProvider } from 'react-helmet-async';


import io from 'socket.io-client';

const socket = io('https://prod-astromind-api.astromindcounsel.com', {
  withCredentials: true,
  transports: ['websocket', 'polling'],
  query: {
    who: 'user'
  }
});

function App() {
  const dispatch = useDispatch();
  const { IpAddress, OpenStatus, OtpStatus, DetailStatus, ConfirmStatus, User, success, errors } = useSelector((state) => state.Astromind);
  const token = JSON.parse(localStorage.getItem('user-auth-token'));

  const orderId = localStorage.getItem("orderId");
  const chatSessionId = localStorage.getItem("messageSessionId");
  const currentUrl = new URL(window.location.href);
  const pathname = currentUrl.pathname;

  const LoginScreenOpen = () => {
    dispatch(LoginScreen({ OpenStatus: true, OtpStatus: false, DetailStatus: false, ConfirmStatus: false }))
  }

  // const UserLogOut = () => {
  //   localStorage.removeItem("user-auth-token");
  //   window.location.reload(false);
  // }

  useEffect(() => {
    if (token) {
      dispatch(GetUserProfile(token));
      // dispatch(CheckLoginStatus(token));
      // dispatch(GetIPAddress());
    }
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(GetIPAddress());
  }, []);

  useEffect(() => {
    if (!(success?.data?.data?.isExpired)) {
      dispatch(successMessage(''));
    }
    if (errors?.data?.data?.isExpired) {
      Swal.fire({
        // title: `${success?.data?.message}`,
        text: `${errors?.data?.message}`,
        showCancelButton: true,
        confirmButtonColor: "#025BFD",
        confirmButtonText: "Login",
        timer: 10000
      }).then(() => {
        localStorage.removeItem("user-auth-token");
        LoginScreenOpen();
        dispatch(errorMessage(''));
      })
    }
  }, [dispatch, success, errors]);




  // console.log(errors);
  const allroutes = [
    { url: "", component: <Home /> },

    { url: "/about/", component: <About /> },


    // { url: "/blogs/", component: <Blog /> },
    { url: "/blogs/", component: <Blog /> },

    { url: "/blogs/:slug/", component: <BlogDetail /> },

    // { url: "/blogs/complete-guide-to-jadhagam/", component: <BlogDetail /> },

    // { url: "/blogs/rajanadi-personal-horoscope-astrology-guide/", component: <RajaNadi /> },

    // { url: "/blogs/tamil-jathagam-raja-nadi-conjunctions-part-one/", component: <RajanadiConjunctions /> },

    // { url: "/rajanadi-conjunctions-tamil-part-1/", component: <RajanadiConjunctionsTamil /> },

    // { url: "/blogs/tamil-jathagam-raja-nadi-conjunctions-part-two/", component: <RajanadiConjunctionsPart2 /> },

    // { url: "/blogs/tamil-jathagam-consultation-new-year-2024/", component: <NewyearPredictions /> },

    // { url: "/blogs/tamil-jathagam-consultation-new-year-part2-2024/", component: <NewyearPredictionsPart2 /> },

    // { url: "/anton-chekov/", component: <AntonChekov /> },

    { url: "/contact-us/", component: <Contact /> },


    { url: "/services/tamil-jathagam-service-for-your-children/", component: <Children /> },

    { url: "/services/couples-horoscope-astrology/", component: <Couples /> },

    { url: "/services/tamil-jathagam-services-for-elder-age/", component: <Elderly /> },

    { url: "/services/end-of-life-horoscope-astrology-services/", component: <Endoflife /> },

    { url: "/services/tamil-jathagam-for-Midlife-crisis/", component: <Midlifecrisis /> },

    { url: "/services/teen-horoscope-astrology-services/", component: <Teens /> },

    { url: "/services/get-personalized-nadi-astrology/", component: <UnlockYourFuture /> },

    { url: "/services/online-vedic-astrology/", component: <EmpowerYourLife /> },

    { url: "/services/best-tamil-jothidam-from-our-expert-tamil-astrologers/", component: <PersonalizedHoroscope /> },

    { url: "/best-astrologers-chennai/", component: <BestAstrologersInChennai /> },

    { url: "/top-astrologers-in-coimbatore/", component: <TopAstrologersInCoimbatore /> },

    { url: "/best-astrologer-trichy/", component: <BestAstrologersInTrichy /> },

    { url: "/best-astrologers-in-madurai/", component: <BestAstrologerInMadurai /> },

    { url: "/best-astrologers-in-thanjavur/", component: <BestAstrologerInThanjavur /> },


    { url: "/faqs/", component: <Faqs /> },

    { url: "/inperson-course/", component: <InPerson /> },

    { url: "/privacy-policies/", component: <PrivacyPolicies /> },

    { url: "/refund-cancellation-policies/", component: <RefundPolicies /> },

    { url: "/terms-of-use/", component: <TermsOfUse /> },

    { url: "/get-your-astrology-reports/", component: <AstrologyReports /> },

    // Dynamic Routes

    { url: "/talk-to-astrologer/", component: <Astrologer astrologers={Astrologer} /> },

    { url: "/chat-with-astrologer/", component: <Astrologer astrologers={Astrologer} /> },

    { url: "/talk-to-astrologer/:slug/:id", component: <Profile astrologers={Astrologer} /> },

    { url: "/chat-with-astrologer/:slug/:id", component: <Profile astrologers={Astrologer} /> },


    { url: "/get-consultation/:slug/:id", component: <ConsultationDetails /> },

    { url: "/chat/", component: <ChatConnect /> },

    { url: "/profile/", component: <UserProfile /> },

    { url: "/notifications/", component: <Notification /> },

    { url: "/my-wallet/", component: <MyWallet /> },

    { url: "/add-wallet-money/", component: <RechargeList /> },

    { url: "/order-history/call-logs/", component: <OrderHistoryList /> },

    { url: "/order-history/chat-logs/", component: <OrderHistoryList /> },

    { url: "/customer-support/", component: <SupportChat /> },


    { url: "/astrologer-register/", component: <AstrologerResgister /> },

    { url: "/chrome-notification/", component: <ChromeNotification /> },

    // { url: "/sitemap.xml" },
  ];


  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]

    console.log(path);
    if (path.includes('/sitemap.xml')) {
      return;
    }
    if (url.indexOf(path) <= 0) {
      return <Error404 />
    }
  }

  return (
    <>
      <Router>
        {
          OpenStatus === true || OtpStatus === true || DetailStatus === true || ConfirmStatus === true ?
            <Login />
            : null
        }
        {
          pathname === '/chat/' ?
            null
            :
            <>
              {
                orderId && chatSessionId ?
                  <ChatWaiting />
                  : orderId ?
                    <CallConnect />
                    : null
              }
            </>
        }

        <Routes>
          <Route element={<MainLayout user={User} />} >
            {allroutes.map((data, i) => (

              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
            ))}
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

function MainLayout({ user }) {
  return (
    <HelmetProvider>
      <div id="main-wrapper">
        <Header user={user} />
        <BrowserNotification socket={socket} />
        {/* {OpenStatus === true || OtpStatus === true || DetailStatus === true || ConfirmStatus === true ? <Login /> : null} */}
        <div className="content-body" >
          <Outlet />
        </div>
        <Footer />
      </div>
    </HelmetProvider>
  )
};

export default App;

