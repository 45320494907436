import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container,Row,Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import BlogdetailBanner from './blogdetailBanner';
import BlogdetailContent from './blogdetailContent';
import BlogdetailSidebar from '../blogdetailSidebar';
import BlogdetailForm from '../blogdetailForm';
import TopBlog from '../topBlog';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetBlog } from '../../../Redux/Slice';


function BlogDetail() {
  const dispatch = useDispatch();
  const { Blog } = useSelector((state) => state.Astromind);
  const { slug } = useParams();

  console.log(Blog);

  useEffect(() => {
    dispatch(GetBlog({ slug }));
}, []);
  return (
    <>
    <Helmet>
        <title>{Blog?.data?.data?.seotitle}</title>
        <meta name="description" data-rh="true" content={Blog?.data?.data?.seodescription} />
        <meta name="keywords" data-rh="true" content={Blog?.data?.data?.seokeywords} />
        <meta name="language" content="en" />
        <meta name="revisit-after" content="7 days" />
        <meta name="Expires" content="never" />
        <meta name="Distribution" content="Global" />
        <meta name="Rating" content="general" />
        <meta name="search engines" content="ALL" />
        <meta name="copyright" content="https://www.astromindcounsel.com/" />
        <meta name="email" content="astromindcounsel@gmail.com" />
        <link rel="canonical" href={`https://www.astromindcounsel.com/blogs/${slug}/`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.astromindcounsel.com/blogs/complete-guide-to-jadhagam/" />
        <meta property="og:site_name" content="https://www.astromindcounsel.com/" />
        <meta property="og:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
        <meta property="og:title" content={Blog?.data?.data?.seotitle} />
        <meta property="og:description" content={Blog?.data?.data?.seodescription} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={Blog?.data?.data?.seotitle} />
        <meta name="twitter:description" content={Blog?.data?.data?.seodescription} />
        <meta name="twitter:image" content="https://www.astromindcounsel.com/astro-og-img.png" />
      </Helmet>

     <BlogdetailBanner blog={Blog?.data?.data} />
     <Container className='mt-5 mb-5'>
       <Row className='d-flex justify-content-between'>
          <Col lg={7}>
            <BlogdetailContent blog={Blog?.data?.data} />
          </Col>
          <Col lg={4}>
            <BlogdetailSidebar blog={Blog?.data?.data} />
          </Col>
         
       </Row>
     </Container>
     {/* <BlogdetailForm /> */}
          <TopBlog />
    </>
  );
}

export default BlogDetail;

